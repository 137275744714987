footer {
  width           : 100%;
  min-height      : 70px;
  background-color: #3d405b;
  display         : grid;
  justify-content : center;
  position        : fixed;
  bottom          : 0px;
  align-items     : center;
  text-transform  : uppercase;
}

.copyright-para {
  color         : #81b29a;
  text-transform: capitalize;
  font-size     : 16px;
  padding-top   : 20px;

}

.social {
  display              : grid;
  grid-template-columns: 50% 9% 9% 9%;
  margin-top           : 30px;
}

.social a {
  width          : 27px;
  height         : 27px;
  display        : inline-flex;
  align-items    : center;
  justify-content: center;
  align-content  : center;
  border-radius  : 50%;
  background     : #81b29a;
  font-size      : 18px;
  margin         : 22px 30px;
  color          : #3d405b;

}

i:hover {
  color: #e9c46a;

}

@media screen and (max-width:768px) {
  footer {
    justify-content: start;
  }

  .copyright-para {
    font-size: 11px;
  }

  .social a {
    width : 23px;
    height: 23px;
    font-size      : 16px;

  }
}
.sec-about {
    width: 100%;
    height      : 100%;
    min-height: 700px;
    padding-top: 77px;
}

h2 {
    text-transform: uppercase;
    font-size     : 26px;
    color         : #2a9d8f;
    letter-spacing: 6px;
    margin-top    : 35px;
    margin-bottom : 10px;
}

.my-name {
    color      : #2a9d8f;
    font-family   : 'Dancing Script', cursive;
    font-size  : 30px;
    text-transform: capitalize;
    letter-spacing: 2px;
}

p {
    width: 100%;
    text-align    : left;
    font-size     : 17px;
    color         : #000000;
    line-height   : 35px;
    font-weight   : bold;
    text-indent   : 1em;
    font-family   : 'Montserrat', sans-serif;
    --animate-duration: 1s;

}
.about-img{
    width: 400px;
    height: 600px;
    padding: 40px;
    border-radius: 50px;
    
}

.my-info {
    display              : grid;
    grid-template-columns: repeat(2,26%);
    padding-top: 4rem;

}

h5 {
    color    : #2a9d8f;
    font-size: 18px;
    text-transform: capitalize;
}
.about-btn{
    margin-top: 20px;
}
@media screen and (max-width:1024px){
    h2 {
        font-size     : 23px;
    }
    h5{
        font-size: 16px;
    }

}

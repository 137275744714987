.sec-portfolio{
    width: 100%;
    height: 100%;
    min-height: 900px;
    padding-top: 80px;
}

.portfolio-title{
    text-align: center;
}

.portfolio-para {
    width: 90%;
    font-family: 'Montserrat', sans-serif;
    padding-top: 40px;

}
.card-container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding-top: 60px;
}
.portfolio-card {
    max-width: 25rem;
    margin: 20px;
    background-color: #eaeceb;
    border: none;
}

.portfolio-imgs{
    padding: 5px;
    transition: all 1s ease-out; 
}
.portfolio-imgs:hover{
    transform: scale(1.0);
    padding: 0px;

}
.porfolio-note{
    font-size: 18px;
}
.card-para{
   font-size: 15px;
}

@media screen and (max-width:768px){
    .card-container{
        grid-template-columns: repeat(1,1fr);
        padding-top: 10px;
    }
}
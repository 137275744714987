* {
  margin            : 0;
  padding           : 0;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
}
body {
  width           : 100%;
  height          : 100vh;
  background-image: url(./images/background-img.png);
  font-size       : 16px;
}

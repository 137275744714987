#skills{
    padding-top: 80px;
}
.sec-skills {
    display              : grid;
    height               : 100%;
    min-height           : 500px;
    grid-template-columns: repeat(3, 1fr);
    align-items          : center;
    margin-top: 40px;
}

.title {
    display       : inline-block;
    width: 100%;
    position      : relative;
    text-transform: capitalize;
    color         : #3d405b;
    font-family   : 'Dancing Script', cursive;
    font-size     : 40px;
    text-align: center; 
    border-bottom: 1px solid #81b29a;
  
}
.skills-subtitle {
    display        : flex;
    justify-content: center;
}
.bx-badge-check{
    font-size: 22px;
    color: #e9c46a;
}

.skills-name {
    font-size   : 20px;
    padding-left: 2rem;
    font-family:'Montserrat', sans-serif;
    font-weight: bold;
}
.skills-level {
    font-size   : 18px;
    color       : #000000;
    padding-left: 2rem;
    text-transform: capitalize;
}
.form {
    width           : 100%;
    max-width       : 80%;
    height          :530px;
  
}

form {
    display: grid;
}

input[type=text],
[type=email],
textarea {
    width        : 70%;
    height       : 65px;
    padding      : 18px 20px;
    margin       : 14px 10px;
    font-size    : 14px;
    border       : 1px solid #81b29a;
    border-radius: 4px;
    box-sizing   : border-box;
}

textarea {
    height: 8rem;
}
.error-message{
    color: red;
    font-size: 14px;
    margin: 0px;
}
.btn{
    margin-top: 3rem;

}

@media screen and (max-width:768px) {
    
    input[type=text],
    [type=email],textarea {
        width : 100%;
        height: 50px;
    }
}


.sec-contact {
    width: 100%;
    height: 100%;
    min-height: 670px;
    margin-top: 4rem;
}
.btn {
    width           : 150px;
    height          : 45px;
    margin          : 10px;
    color           : #111111;
    border-radius   : 8px;
    border: none;
    font-size       : 22px;
    font-family: 'Dancing Script', cursive;
    letter-spacing: 2px;
    text-transform: capitalize;
    cursor          : pointer;
    background: linear-gradient(to top, #ecac9a, #a3cdb9) #f2cc8f;
    transition: ease .60s;
  }
  
  .btn:hover {
    transform: scale(1.1);
    border          : 1px solid #ecac9a;
  }

@media screen and (max-width:768px){
    .sec-contact{
      min-height: 400px;
    }

}
nav {
    width           : 100%;
    height          : 80px;
    top             : 0;
    right           : 0;
    background-color: #3d405b;
    display         : flex;
    justify-content : space-around;
    align-items     : center;
    padding         : 25px 3%;
    z-index         : 10000;
    position        : fixed;
}

.logo {
    text-transform: capitalize;
    color         : #f4f1de;
    font-size     : 32px;
    font-family   : 'Dancing Script', cursive;

}

.logo::first-letter {
    color    : #81b29a;
    font-size: 40px;
}

span {
    font-size  : 45px;
    color      : #81b29a;
    margin-left: 4px;
}

.nav-links {
    display    : flex;
    align-items: center;
}
li {
    list-style-type: none;
    margin-left    : 3rem;
    margin-top     : 1rem;
}

a {

    color         : #e9c46a;
    font-size     : 16px;
    text-transform: uppercase;
    cursor        : pointer;
    transition    : ease .10s;
}
a:hover {
    color          : #81b29a;
    text-decoration: none;

}


.top-btn {
    display      : inline-block;
    padding      : 9px 30px;
    border       : 2px solid #81b29a;
    border-radius: 30px;
    font-size    : 14px;
    transition   : ease .50s;
}

.top-btn:hover {
    transform: scale(1.1);
    border   : 2px solid #e9c46a;
}

.mobile-menu-icon {
    display: none;
}

@media screen and (max-width:1024px) {
    .logo {
        display: inline-block;
    }

    .nav-links {
        display: none;
    }

    .nav-links-mobile {
        display         : block;
        position        : absolute;
        list-style      : none;
        background-color: #e9c46a;
        left            : 0;
        top             : 70px;
        transition      : all 0.5s ease-out;
        width           : 100%;
        height: 100vh;
    }

    li {
        text-align : center;
        padding    : 20px;
        width      : 100%;
        margin-left: 0px;

    }

    a {
        color: #3d405b;
    }

    .top-btn {
        border: none;
    }

    .mobile-menu-icon {
        display         : block;
        position        : absolute;
        font-size       : 30px;
        color           : #ffffff;
        background-color: #3d405b;
        border          : none;
        outline         : none;
        top             : 15px;
        right           : 25px;
    }
}

.sec-home {
    width             : 100%;
    height: 100%;
    min-height: 600px;
    display           : grid;
    grid-template-rows: repeat(4,20%);   
    align-content: center;
    justify-content: center;
    padding-top: 6rem;
    background-image: url(../images/Rouge\ et\ Blanc\ Illustré\ Anglais\ Tuteur\ Marketing\ Présentation\ \(2\).png);
    background-position: center ;
    background-repeat: no-repeat;
    background-size: contain;
    
}

.greeting-word {
    color         : #e9c46a;
    text-transform: capitalize;
    font-family   : 'Dancing Script', cursive;
    font-weight: bolder;
    font-size     : 28px;
    letter-spacing: 5px;
}

h4 {

    font-size     : 30px;
    letter-spacing: 5px;
}

@media screen and (max-width:1024px){
    .sec-home{
        background-image: none;
        grid-template-rows: repeat(4,18%); 
        margin-left: 10px;
    }
    h4 {
        letter-spacing: 1px;
    }

}


